<template>
  <div class="section-3 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <div class="text-center evo-text-58 evo-mb-8 evo-title">
        {{ $t('mer_services.s3_title') }}
      </div>
      <div class="text-center evo-text-30 evo-opensans-regular evo-mb-120 evo-subtitle">
        {{ $t('mer_services.s3_subtitle') }}
      </div>

      <v-row class="evo-row">
        <v-col class="evo-mb-32" cols="12" v-for="(item, index) in mergedItems" :key="index">
          <div class="evo-items-wrapper">
            <img loading="lazy" class="evo-item-img" :src="item.icon" />
            <div class="evo-item-header evo-text-61">{{ $t(item.header) }}</div>
            <div class="evo-item-desc evo-text-36 evo-opensans-regular">
              {{ $t(item.desc) }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { onlineMerchantAcquiringSection3Mixin } from '@/mixins/online-merchant-acquiring.js';
export default {
  name: 'EvoMOnlineMerchantAcquiringSectionThree',
  mixins: [onlineMerchantAcquiringSection3Mixin],
  data() {
    return {
      mergedItems: []
    };
  },
  created() {
    this.mergedItems = [...this.items1, ...this.items2];
  }
};
</script>
<style lang="scss" scoped>
.section-3 {
  position: relative;

  .evo-title {
    color: #222a41;
  }

  .evo-subtitle {
    color: #a0a3a8;
  }

  .evo-items-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 25px 54px 0px rgba(0, 0, 0, 0.2);
    border-radius: 48px;
    height: 100%;
    padding: 48px;
    width: 589px;
    margin: 0 auto;

    .evo-item-img {
      width: 245px;
      height: auto;
      text-align: center;
    }

    .evo-item-header {
      text-align: center;
      margin-bottom: 8px;
      min-height: 54px;
    }

    .evo-item-desc {
      text-align: center;
    }
  }
}
</style>
