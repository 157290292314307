<template>
  <div class="section-4 evo-ph-206 evo-pv-60">
    <v-row class="evo-row" align="baseline" justify="space-between">
      <v-col cols="12" sm="12" md="6">
        <div class="evo-text-32 evo-title" data-aos="fade-up">
          {{ $t("mer_services.s4_title") }}
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <div
          class="evo-text-14 evo-desc evo-opensans-regular evo-mb-16"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {{ $t("mer_services.s4_desc") }}
        </div>
        <router-link to="contact-us" class="router-link">
          <v-btn
            rounded
            x-large
            color="primary"
            class="text-none evo-text-9 evo-learn-more-btn"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            {{ $t("mer_services.learn_details") }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <div class="evo-img" data-aos="fade-up" data-aos-delay="200">
      <img loading="lazy"  class="evo-img" src="@/assets/merchant-services/s4-1-img.png" />
    </div>

    <EvoEllipse
      class="evo-s4-ellipse"
      background="yellow"
      transform="rotate(0deg)"
      filter="blur(25px)"
      width="200px"
      height="200px"
    />
  </div>
</template>
<script>
import { onlineMerchantAcquiringSection4Mixin } from '@/mixins/online-merchant-acquiring.js';
export default {
  name: "EvoMerchantServicesSectionFour",
  mixins: [onlineMerchantAcquiringSection4Mixin]
};
</script>
<style lang="scss" scoped>
.section-4 {
  background-color: #fafafa;
  position: relative;

  .evo-title {
    color: #151517;
    position: relative;
    z-index: 1;
  }

  .evo-desc {
    color: #676767;
  }

  .evo-img {
    margin-top: 20px;
    width: 100%;
    border-radius: 20px;
  }

  .evo-learn-more-btn {
    @include lightblue_btn;
  }

  .evo-s4-ellipse {
    position: absolute;
    left: 180px;
    top: 48px;
    z-index: 0;
  }
}
</style>
