<template>
  <div class="section-2 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <div class="evo-s2-bg"></div>

      <EvoRectWithDotted :isMobile="true" class="evo-rect-with-dotted1" />
      <EvoRectWithDotted :isMobile="true" class="evo-rect-with-dotted2" />
      <EvoRectWithDotted :isMobile="true" class="evo-rect-with-dotted3" />
      <div class="evo-rot-rect1"></div>
      <div class="evo-rot-rect2"></div>
      <EvoMSectionTwoItems :title="group1.title" :subtitle="group1.subtitle" :items="group1.items" />
      <EvoMSectionTwoItems :title="group2.title" :subtitle="group2.subtitle" :items="group2.items" />
      <EvoMSectionTwoItems :title="group3.title" :subtitle="group3.subtitle" :items="group3.items" />

      <div class="evo-trans-process-wrapper">
        <EvoDiamond class="evo-mb-32" />
        <div class="evo-text-58 evo-title text-center evo-mb-8">
          {{ $t('mer_services.trans_process') }}
        </div>
        <div class="evo-subtitle evo-text-30 text-center evo-mb-120 evo-opensans-regular">
          {{ $t('mer_services.trans_process_sub') }}
        </div>
        <div class="evo-items-wrapper">
          <div class="evo-item" v-for="(item, index) in items" :key="index">
            <img loading="lazy" class="evo-item-img" :src="item.icon" />
            <div class="evo-item-title evo-text-33">
              {{ $t(item.title) }}
            </div>
            <img loading="lazy" v-if="item.arrowR" src="@/assets/arrow_right.svg" class="evo-arrow-r" />
          </div>
        </div>
      </div>

      <EvoEllipse class="evo-ellipse" background="yellow" transform="rotate(0deg)" filter="blur(25px)" width="200px" height="200px" />
    </div>
  </div>
</template>
<script>
import { onlineMerchantAcquiringSection2Mixin } from '@/mixins/online-merchant-acquiring.js';
import EvoMSectionTwoItems from '@/mobile/components/online_merchant_sections/MSectionTwoItems.vue';
export default {
  name: 'EvoMOnlineMerchantAcquiringSectionTwo',
  mixins: [onlineMerchantAcquiringSection2Mixin],
  components: { EvoMSectionTwoItems }
};
</script>
<style lang="scss" scoped>
.section-2 {
  position: relative;
  overflow: hidden;
  padding-bottom: 160px;

  .evo-title {
    position: relative;
    color: #222a41;
  }

  .evo-subtitle {
    text-align: center;
    color: #a0a3a8;
  }

  .evo-items-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 625px;
    background: #ffffff;
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 32px 24px;

    .evo-item {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      width: 100%;

      .evo-item-img {
        width: 144px;
        height: auto;
        text-align: center;
      }

      .evo-item-title {
        text-align: center;
        margin-bottom: 92px;
        white-space: pre-line;
      }

      .evo-arrow-r {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
        top: 282px;
        width: 52px;
        height: auto;
      }
    }

    .evo-item:last-child {
      .evo-item-title {
        margin-bottom: 62px;
      }
    }
  }

  .evo-s2-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 216vw;
    background-image: url('~@/assets/merchant-services/s2-bg.svg') !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 10vw;
    border-top-right-radius: 10vw;
    z-index: 0;
  }

  .evo-trans-process-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .evo-ellipse {
    position: absolute;
    right: 140px;
    top: 1500px;
    z-index: 0;
  }

  .evo-rect-with-dotted1 {
    position: absolute;
    left: 14px;
    top: 614px;
  }

  .evo-rect-with-dotted2 {
    position: absolute;
    right: 14px;
    top: 4292px;
  }

  .evo-rect-with-dotted3 {
    position: absolute;
    left: 14px;
    top: 6654px;
  }

  .evo-rot-rect1 {
    position: absolute;
    left: 40vw;
    top: 100vw;
    background: linear-gradient(180deg, #485c91 0%, #415483 100%);
    border-radius: 84px;
    transform: rotate(-45deg);
    width: 320vw;
    height: 320vw;
    opacity: 0.1;
    z-index: 0;
  }

  .evo-rot-rect2 {
    position: absolute;
    left: 40vw;
    top: 320vw;
    background: linear-gradient(180deg, #485c91 0%, #415483 100%);
    border-radius: 84px;
    transform: rotate(-45deg);
    width: 300vw;
    height: 300vw;
    opacity: 0.1;
    z-index: 0;
  }
}
</style>
