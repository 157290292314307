<template>
  <div class="section-3 evo-ph-206 evo-pv-60">
    <div class="evo-s3-bg"></div>

    <div class="text-center evo-text-28 evo-mb-8 evo-title" data-aos="fade-up">
      {{ $t('mer_services.s3_title') }}
    </div>
    <div class="text-center evo-text-14 evo-opensans-regular evo-mb-100 evo-subtitle" data-aos="fade-up" data-aos-delay="100">
      {{ $t('mer_services.s3_subtitle') }}
    </div>

    <v-row class="evo-row">
      <v-col v-for="(item, index) in items1" :key="index" data-aos="fade-up" data-aos-delay="150">
        <div class="evo-items-wrapper">
          <img loading="lazy" class="evo-item-img" :src="item.icon" />
          <div class="evo-item-header evo-text-18">{{ $t(item.header) }}</div>
          <div class="evo-item-desc evo-text-11 evo-opensans-regular">
            {{ $t(item.desc) }}
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="evo-row" justify="center">
      <v-col v-for="(item, index) in items2" :key="index" cols="12" sm="12" md="4" data-aos="fade-up" data-aos-delay="150">
        <div class="evo-items-wrapper evo-item-margin">
          <img loading="lazy" class="evo-item-img" :src="item.icon" />
          <div class="evo-item-header evo-text-18">{{ $t(item.header) }}</div>
          <div class="evo-item-desc evo-text-11 evo-opensans-regular">
            {{ $t(item.desc) }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { onlineMerchantAcquiringSection3Mixin } from '@/mixins/online-merchant-acquiring.js';
export default {
  name: 'EvoMerchantServicesSectionThree',
  mixins: [onlineMerchantAcquiringSection3Mixin]
};
</script>
<style lang="scss" scoped>
.section-3 {
  position: relative;
  background-image: url('~@/assets/merchant-services/s3-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 200px;

  .evo-title {
    color: #222a41;
  }

  .evo-subtitle {
    color: #a0a3a8;
  }

  .evo-s3-bg {
    position: absolute;
    background-image: url('~@/assets/merchant-services/s3-bg-circle.svg');
    background-repeat: no-repeat;
    background-size: contain;
    top: 200px;
    left: 60%;
    transform: translateX(-65%);
    width: 78%;
    height: 78%;
    z-index: 0;
  }

  .evo-items-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    height: 100%;
    padding: 24px 16px;

    .evo-item-img {
      width: 60px;
      height: 60px;
      text-align: center;
    }

    .evo-item-header {
      text-align: center;
      margin-bottom: 8px;
      min-height: 54px;
    }

    .evo-item-desc {
      text-align: center;
    }
  }

  .evo-item-margin {
    width: 100%;
  }
}
</style>
