<template>
  <div class="evo-merchant-services-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMOnlineMerchantAcquiringSectionOne />
      <EvoMOnlineMerchantAcquiringSectionTwo />
      <EvoMOnlineMerchantAcquiringSectionThree />
      <EvoMOnlineMerchantAcquiringSectionFour />
    </template>
    <template v-else>
      <EvoMerchantServicesSectionOne />
      <EvoMerchantServicesSectionTwo />
      <EvoMerchantServicesSectionThree />
      <EvoMerchantServicesSectionFour />
    </template>
  </div>
</template>
<script>
import EvoMerchantServicesSectionOne from '@/components/online_merchant_sections/Section_1.vue';
import EvoMerchantServicesSectionTwo from '@/components/online_merchant_sections/Section_2.vue';
import EvoMerchantServicesSectionThree from '@/components/online_merchant_sections/Section_3.vue';
import EvoMerchantServicesSectionFour from '@/components/online_merchant_sections/Section_4.vue';

// mobile imports
import EvoMOnlineMerchantAcquiringSectionOne from '@/mobile/components/online_merchant_sections/MSection_1.vue';
import EvoMOnlineMerchantAcquiringSectionTwo from '@/mobile/components/online_merchant_sections/MSection_2.vue';
import EvoMOnlineMerchantAcquiringSectionThree from '@/mobile/components/online_merchant_sections/MSection_3.vue';
import EvoMOnlineMerchantAcquiringSectionFour from '@/mobile/components/online_merchant_sections/MSection_4.vue';
export default {
  name: 'EvoMerchantServices',
  components: {
    EvoMerchantServicesSectionOne,
    EvoMerchantServicesSectionTwo,
    EvoMerchantServicesSectionThree,
    EvoMerchantServicesSectionFour,

    EvoMOnlineMerchantAcquiringSectionOne,
    EvoMOnlineMerchantAcquiringSectionTwo,
    EvoMOnlineMerchantAcquiringSectionThree,
    EvoMOnlineMerchantAcquiringSectionFour
  }
};
</script>
<style lang="scss" scoped>
.evo-merchant-services-wrapper {
  position: relative;
  background-color: #fafafa;
}
</style>
