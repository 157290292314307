<template>
  <div class="section-4 evo-f-center evo-pv-60">
    <div class="evo-mobile">
      <div class="evo-text-58 text-center evo-title">
        {{ $t('mer_services.s4_title') }}
      </div>
      <div class="evo-text-30 evo-desc evo-opensans-regular evo-mb-16">
        {{ $t('mer_services.s4_desc') }}
      </div>
      <router-link to="contact-us" class="router-link">
        <v-btn rounded x-large color="primary" class="text-none evo-learn-more-btn">
          <span class="evo-text-29">{{ $t('mer_services.learn_details') }}</span>
        </v-btn>
      </router-link>

      <div class="evo-img">
        <img loading="lazy" class="evo-img" src="@/assets/merchant-services/s4-1-img.png" />
      </div>

      <EvoEllipse class="evo-s4-ellipse" background="yellow" transform="rotate(0deg)" filter="blur(25px)" width="200px" height="200px" />
    </div>
  </div>
</template>
<script>
import { onlineMerchantAcquiringSection4Mixin } from '@/mixins/online-merchant-acquiring.js';
export default {
  name: 'EvoMOnlineMerchantAcquiringSectionFour',
  mixins: [onlineMerchantAcquiringSection4Mixin]
};
</script>
<style lang="scss" scoped>
.section-4 {
  background-color: #fafafa;
  position: relative;

  .evo-title {
    color: #151517;
    position: relative;
    z-index: 1;
    margin-bottom: 64px;
  }

  .evo-desc {
    color: #676767;
    position: relative;
    margin-bottom: 64px;
    z-index: 1;
  }

  .evo-img {
    width: 100%;
    border-radius: 20px;
  }

  .evo-learn-more-btn {
    @include lightblue_btn;
    min-width: 280px;
    margin-bottom: 64px;
  }

  .evo-s4-ellipse {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
  }
}
</style>
