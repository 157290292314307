<template>
  <div class="section-2-items">
    <div class="evo-title evo-text-58 evo-mb-8">
      {{ $t(title) }}
    </div>
    <div class="evo-subtitle evo-text-30 evo-mb-120 evo-opensans-regular">
      {{ $t(subtitle) }}
    </div>
    <v-row class="evo-row" justify="space-between">
      <v-col class="evo-mb-32" cols="12" v-for="(item, index) in items" :key="index">
        <div class="evo-item-wrapper">
          <img loading="lazy" class="evo-item-img" :src="item.icon" />
          <div class="evo-item-header evo-text-43">{{ $t(item.header) }}</div>
          <div class="evo-item-desc evo-text-33 evo-opensans-regular">
            {{ $t(item.desc) }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'EvoMSectionTwoItems',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  }
};
</script>
<style lang="scss" scoped>
.section-2-items {
  position: relative;
  margin-bottom: 140px;
  z-index: 1;

  .evo-title {
    text-align: center;
    color: #222a41;
  }

  .evo-subtitle {
    text-align: center;
    color: #a0a3a8;
  }

  .evo-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 8px 17px 0px rgba(69, 158, 255, 0.1);
    border-radius: 17px;
    border: 1px solid #f2f2f2;
    padding: 32px;
    height: 100%;
    margin: 0 auto;
    max-width: 554px;

    .evo-item-img {
      width: 172px;
      height: auto;
      text-align: center;
    }

    .evo-item-header {
      color: #222a41;
      text-align: center;
      margin-bottom: 12px;
    }

    .evo-item-desc {
      color: #6d7783;
      text-align: center;
    }
  }
}
</style>
