import EvoRectWithDotted from "@/components/RectWithDotted.vue";
import EvoDiamond from "@/components/Diamond.vue";
import EvoEllipse from "@/components/Ellipse.vue";
export const onlineMerchantAcquiringSection2Mixin = {
    components: {
        EvoRectWithDotted,
        EvoDiamond,
        EvoEllipse,
    },
    data: () => ({
        group1: {
            title: "mer_services.s2_title1",
            subtitle: "mer_services.s2_subtitle1",
            items: [
                {
                    icon: require("@/assets/merchant-services/s2-icon-1.png"),
                    header: "mer_services.s2_header1",
                    desc: "mer_services.s2_desc1",
                },
                {
                    icon: require("@/assets/merchant-services/s2-icon-2.png"),
                    header: "mer_services.s2_header2",
                    desc: "mer_services.s2_desc2",
                },
                {
                    icon: require("@/assets/merchant-services/s2-icon-3.png"),
                    header: "mer_services.s2_header3",
                    desc: "mer_services.s2_desc3",
                },
            ],
        },
        group2: {
            title: "mer_services.s2_title2",
            subtitle: "mer_services.s2_subtitle2",
            items: [
                {
                    icon: require("@/assets/merchant-services/s2-icon-4.png"),
                    header: "mer_services.s2_header4",
                    desc: "mer_services.s2_desc4",
                },
                {
                    icon: require("@/assets/merchant-services/s2-icon-5.png"),
                    header: "mer_services.s2_header5",
                    desc: "mer_services.s2_desc5",
                },
                {
                    icon: require("@/assets/merchant-services/s2-icon-6.png"),
                    header: "mer_services.s2_header6",
                    desc: "mer_services.s2_desc6",
                },
            ],
        },
        group3: {
            title: "mer_services.s2_title3",
            subtitle: "mer_services.s2_subtitle3",
            items: [
                {
                    icon: require("@/assets/merchant-services/s2-icon-7.png"),
                    header: "mer_services.s2_header7",
                    desc: "mer_services.s2_desc7",
                },
                {
                    icon: require("@/assets/merchant-services/s2-icon-8.png"),
                    header: "mer_services.s2_header8",
                    desc: "mer_services.s2_desc8",
                },
                {
                    icon: require("@/assets/merchant-services/s2-icon-9.png"),
                    header: "mer_services.s2_header9",
                    desc: "mer_services.s2_desc9",
                },
            ],
        },
        items: [
            {
                icon: require("@/assets/merchant-services/receive-trans.png"),
                title: "mer_services.s2_trans_title1",
                arrowR: true,
                delay: 400,
            },
            {
                icon: require("@/assets/merchant-services/risk-control-engine.png"),
                title: "mer_services.s2_trans_title2",
                arrowR: true,
                delay: 600,
            },
            {
                icon: require("@/assets/merchant-services/unified-payment.png"),
                title: "mer_services.s2_trans_title3",
                arrowR: true,
                delay: 800,
            },
            {
                icon: require("@/assets/merchant-services/settle-engine.png"),
                title: "mer_services.s2_trans_title4",
                arrowR: true,
                delay: 1000,
            },
            {
                icon: require("@/assets/merchant-services/payout.png"),
                title: "mer_services.s2_trans_title5",
                arrowR: false,
                delay: 1200,
            },
        ],
    }),

}

export const onlineMerchantAcquiringSection3Mixin = {
    data: () => ({
        items1: [
            {
                icon: require("@/assets/merchant-services/s3-icon-1.png"),
                header: "mer_services.s3_header1",
                desc: "mer_services.s3_desc1",
            },
            {
                icon: require("@/assets/merchant-services/s3-icon-2.png"),
                header: "mer_services.s3_header2",
                desc: "mer_services.s3_desc2",
            },
            {
                icon: require("@/assets/merchant-services/s3-icon-3.png"),
                header: "mer_services.s3_header3",
                desc: "mer_services.s3_desc3",
            },
        ],
        items2: [
            {
                icon: require("@/assets/merchant-services/s3-icon-4.png"),
                header: "mer_services.s3_header4",
                desc: "mer_services.s3_desc4",
            },
            {
                icon: require("@/assets/merchant-services/s3-icon-5.png"),
                header: "mer_services.s3_header5",
                desc: "mer_services.s3_desc5",
            },
        ],
    }),
}

export const onlineMerchantAcquiringSection4Mixin = {
    components: {
        EvoEllipse,
    }
}