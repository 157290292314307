<template>
  <div class="evo-rect-with-dotted" :class="{ 'evo-rect-with-dotted-mobile': isMobile }">
    <div class="dot" :class="{ 'dot-mobile': isMobile }" v-for="(item, index) in dots" :key="index"> </div>
  </div>
</template>
<script>
export default {
  name: 'EvoRectWithDotted',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dots: 64
  })
};
</script>
<style lang="scss" scoped>
.evo-rect-with-dotted {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 162px;
  height: 162px;

  &.evo-rect-with-dotted-mobile {
    width: 332px;
    height: 332px;
  }

  .dot {
    width: 4px;
    height: 4px;
    background: #3b43f2;
    border-radius: 50%;
    margin: 8px;

    &.dot-mobile {
      width: 8px;
      height: 8px;
      margin: 16px;
    }
  }
}
</style>
