<template>
  <div class="section-1 section-1-bg evo-ph-176 evo-pv-60">
    <v-row class="evo-row" align="center">
      <v-col cols="12" sm="12" md="6">
        <div
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          class="evo-text-38 evo-mb-16"
        >
          {{ $t("mer_services.s1_title") }}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          class="evo-text-15 evo-mb-32 evo-opensans-regular"
        >
          {{ $t("mer_services.s1_desc") }}
        </div>
        <div class="evo-get-started">
          <router-link to="contact-us" class="router-link">
            <v-btn
              data-aos="fade-up"
              data-aos-delay="150"
              data-aos-duration="1000"
              rounded
              x-large
              color="default"
              class="text-none evo-text-9 evo-get-started-btn"
            >
              {{ $t("contact_us") }}
            </v-btn>
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" align-self="center">
        <div class="evo-img-wrapper">
          <img
            loading="lazy"
            class="evo-img"
            src="@/assets/merchant-services/phone.png"
          />
          <img
            loading="lazy"
            class="evo-icon evo-icon-1"
            src="@/assets/merchant-services/s1-icon-1.png"
          />
          <img
            loading="lazy"
            class="evo-icon evo-icon-2"
            src="@/assets/merchant-services/s1-icon-2.png"
          />
          <img
            loading="lazy"
            class="evo-icon evo-icon-3"
            src="@/assets/merchant-services/s1-icon-3.png"
          />
          <img
            loading="lazy"
            class="evo-icon evo-icon-4"
            src="@/assets/merchant-services/s1-icon-4.png"
          />
          <img
            loading="lazy"
            class="evo-icon evo-icon-5"
            src="@/assets/merchant-services/s1-icon-5.png"
          />
          <img
            loading="lazy"
            class="evo-icon evo-icon-6"
            src="@/assets/merchant-services/s1-icon-6.png"
          />
          <div class="evo-animation-container">
            <div class="wave wave7"></div>
            <div class="wave wave6"></div>
            <div class="wave wave5"></div>
            <div class="wave wave4"></div>
            <div class="wave wave3"></div>
            <div class="wave wave2"></div>
            <div class="wave wave1"></div>

            <img
              loading="lazy"
              class="logo"
              src="@/assets/about-us/EGMS-logo.png"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "EvoMerchantServicesSectionOne",
};
</script>
<style lang="scss" scoped>
$phoneWidth: 218px;
$phoneHeight: 430px;
$phoneInnerWidth: 198px;
$phoneInnerHeight: 410px;
$w50px: 4.62963vw;
$w100px: 9.259259vw;
$w150px: 13.63636vw;
$w200px: 17.93939vw;
$w250px: 22.42424vw;
$w300px: 26.91818vw;
$w350px: 31.41241vw;
$w400px: 35.9069vw;

$waves: 1 0.1s #99b4fc, 2 0.2s #83a4ff, 3 0.3s #5b7ef8, 4 0.4s #3153e7,
  5 0.5s #213ac5, 6 0.6s #1b259f, 7 0.7s #0f1279;
$keyframes: w1 $w400px, w2 $w350px, w3 $w300px, w4 $w250px, w5 $w200px,
  w6 $w150px, w7 $w100px, w8 $w50px;

.section-1 {
  position: relative;
  padding-right: 100px;
  color: #fff;
  // height: 100%;
  background-color: $nav_color;
  .evo-get-started {
    display: flex;

    .evo-get-started-btn {
      @include whiteblue_btn;
      min-width: 128px;
      color: #459eff;
    }
  }

  .evo-img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;

    .evo-img {
      width: $phoneWidth;
      height: $phoneHeight;
      z-index: 1;
    }

    .evo-icon {
      position: absolute;
      width: 55px;
      height: 59px;
      animation: moveUpDown 2s infinite alternate;
    }

    .evo-icon-1 {
      top: 24px;
      left: -10px;
      animation-delay: random(8) * 0.1s !important;
    }

    .evo-icon-2 {
      top: calc(50% - 39px);
      left: 0;
      animation-delay: random(8) * 0.1s !important;
    }

    .evo-icon-3 {
      bottom: 24px;
      left: -10px;
      animation-delay: random(8) * 0.1s !important;
    }

    .evo-icon-4 {
      top: 12px;
      right: 0;
      animation-delay: random(8) * 0.1s !important;
    }

    .evo-icon-5 {
      top: calc(50% - 51px);
      right: -10px;
      animation-delay: random(8) * 0.1s !important;
    }

    .evo-icon-6 {
      bottom: 36px;
      right: 0;
      animation-delay: random(8) * 0.1s !important;
    }

    .evo-animation-container {
      position: absolute;
      left: 98px;
      top: 12px;
      overflow: hidden;
      border-radius: 18px;
      width: $phoneInnerWidth;
      height: $phoneInnerHeight;
      background-color: #a9c5ff;
      z-index: 0;

      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
        opacity: 0;
        animation: logo_animation 1s 0.7s forwards;
        z-index: 10;
      }

      .wave {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0px;
        height: 0px;
        border-radius: 50%;
      }

      @each $i, $delay, $color in $waves {
        .wave#{$i} {
          background: rgba($color, 1);
          z-index: $i;
          animation: w#{$i} 1s $delay forwards;
        }
      }
    }
  }

  @each $name, $width in $keyframes {
    @keyframes #{$name} {
      0% {
        width: 0px;
        height: 0px;
      }

      100% {
        width: #{$width};
        height: #{$width};
      }
    }
  }

  @keyframes logo_animation {
    0% {
      opacity: 0;
      width: 0;
      height: 0;
    }

    100% {
      opacity: 1;
      width: $w50px;
      height: $w50px;
    }
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
</style>
