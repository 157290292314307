<template>
  <div class="section-2 evo-ph-206 evo-pv-60">
    <div class="evo-s2-bg"></div>

    <EvoRectWithDotted class="evo-rect-with-dotted1" />
    <EvoRectWithDotted class="evo-rect-with-dotted2" />
    <EvoRectWithDotted class="evo-rect-with-dotted3" />
    <div class="evo-rot-rect1"></div>
    <div class="evo-rot-rect2"></div>
    <EvoSectionTwoItems :title="group1.title" :subtitle="group1.subtitle" :items="group1.items" />
    <EvoSectionTwoItems :title="group2.title" :subtitle="group2.subtitle" :items="group2.items" />
    <EvoSectionTwoItems :title="group3.title" :subtitle="group3.subtitle" :items="group3.items" />

    <div class="evo-trans-process-wrapper">
      <EvoDiamond class="evo-mb-32" />
      <div class="evo-text-28 evo-title evo-mb-8" data-aos="fade-up">
        {{ $t('mer_services.trans_process') }}
      </div>
      <div class="evo-subtitle evo-text-14 evo-mb-120 evo-opensans-regular" data-aos="fade-up" data-aos-delay="100">
        {{ $t('mer_services.trans_process_sub') }}
      </div>
      <div class="evo-items-wrapper" data-aos="fade-up" data-aos-delay="300">
        <div class="evo-item" v-for="(item, index) in items" :key="index">
          <img loading="lazy" class="evo-item-img" :src="item.icon" data-aos="zoom-in" :data-aos-delay="item.delay" />
          <div class="evo-item-title evo-text-13" data-aos="zoom-in" :data-aos-delay="item.delay">
            {{ $t(item.title) }}
          </div>
          <img loading="lazy" v-if="item.arrowR" data-aos="zoom-in" :data-aos-delay="item.delay" src="@/assets/arrow_right.svg" class="evo-arrow-r" />
        </div>
      </div>
    </div>

    <EvoEllipse class="evo-ellipse" background="yellow" transform="rotate(0deg)" filter="blur(25px)" width="200px" height="200px" />
  </div>
</template>
<script>
import { onlineMerchantAcquiringSection2Mixin } from '@/mixins/online-merchant-acquiring.js';
import EvoSectionTwoItems from '@/components/online_merchant_sections/SectionTwoItems.vue';
export default {
  name: 'EvoMerchantServicesSectionTwo',
  mixins: [onlineMerchantAcquiringSection2Mixin],
  components: { EvoSectionTwoItems }
};
</script>
<style lang="scss" scoped>
.section-2 {
  position: relative;
  overflow: hidden;
  padding-bottom: 160px;

  .evo-title {
    position: relative;
    color: #222a41;
  }

  .evo-subtitle {
    text-align: center;
    color: #a0a3a8;
  }

  .evo-items-wrapper {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 32px 24px;

    .evo-item {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      width: 20%;

      .evo-item-img {
        width: 60px;
        height: 60px;
        text-align: center;
      }

      .evo-item-title {
        text-align: center;
      }

      .evo-arrow-r {
        position: absolute;
        right: -6px;
        top: 18%;
        font-size: 18px;
        width: 20px;
        height: auto;
      }
    }
  }

  .evo-s2-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 24vw;
    background-image: url('~@/assets/merchant-services/s2-bg.svg') !important;
    background-size: cover;
    z-index: 0;
  }

  .evo-trans-process-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .evo-ellipse {
    position: absolute;
    right: 140px;
    top: 1500px;
    z-index: 0;
  }

  .evo-rect-with-dotted1 {
    position: absolute;
    left: 154px;
    top: 392px;
  }

  .evo-rect-with-dotted2 {
    position: absolute;
    right: 126px;
    top: 1020px;
  }

  .evo-rect-with-dotted3 {
    position: absolute;
    left: 146px;
    top: 1610px;
  }

  .evo-rot-rect1 {
    position: absolute;
    left: 36vw;
    top: -40vw;
    background: linear-gradient(180deg, #485c91 0%, #415483 100%);
    border-radius: 84px;
    transform: rotate(-45deg);
    width: 120vw;
    height: 120vw;
    opacity: 0.1;
    z-index: 0;
  }

  .evo-rot-rect2 {
    position: absolute;
    left: 54vw;
    top: 10vw;
    background: linear-gradient(180deg, #485c91 0%, #415483 100%);
    border-radius: 84px;
    transform: rotate(-45deg);
    width: 200vw;
    height: 200vw;
    opacity: 0.1;
    z-index: 0;
  }
}
</style>
